.CourseBodyMain {
  width: 100%;
  height: calc(98vh - 4rem);
  flex: 1;

  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.CourseMainCard {
  width: 350px;
  height: 200px;
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.CourseMainCardImg {
  width: 300px;
  height: 170px;
  border-radius: 15px;
  object-fit: contain;
}

.CourseMainCardBody {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.CourseMainCardBody h1 {
  font-size: 13px;
  padding-top: 10px;
  text-transform: uppercase;
  font-weight: 600;
  color: black;
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.react-tabs__tab-list {
  border-bottom: 0px !important;
}
.react-tabs__tab--selected {
  border: none !important;
}
/* CSS */
.button-10 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
  border-radius: 6px;
  border: none;

  color: #fff;
  background: #bc0000;
  background-origin: border-box;

  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-10:focus {
  outline: 0;
}

@media (max-width: 767px) {
  .header__search {
    display: none;
  }
}

.my-btn,
.my-btn-border,
.btn-bell {
  border-radius: 50%;
  /* position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%); */
}

.headersideTop {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.my-btn {
  height: 40px;
  width: 40px;
  box-shadow: -1px 2px 10px #999;
  background: #bc0000;
  animation-name: col;
  animation-duration: 2s;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-iteration-count: infinite;
}

.btn-bell {
  color: white;
  font-size: 20px;
  animation-name: bell-ring;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
@keyframes bord-pop {
  0% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.9);
    opacity: 0.1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.9);
    opacity: 0;
  }
}
@keyframes col {
  0% {
    transform: scale(1) translate(0, 0);
  }
  10% {
    transform: scale(1.1) translate(0, 0);
  }
  75% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(1) translate(0, 0);
  }
}
@keyframes bell-ring {
  0% {
    transform: translate(-50%, -50%);
  }
  5%,
  15% {
    transform: translate(-50%, -50%) rotate(25deg);
  }
  10%,
  20% {
    transform: translate(-50%, -50%) rotate(-25deg);
  }
  25% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

.header__container {
  flex-direction: row-reverse;
}

@media (max-width: 767px) {
  .header__container {
    flex-direction: row !important;
  }
}

.dropdown {
  position: relative;
  padding: 0;
  margin-right: 1em;

  border: none;
}

.dropdown summary {
  list-style: none;
  list-style-type: none;
}

.dropdown > summary::-webkit-details-marker {
  display: none;
}

.dropdown summary:focus {
  outline: none;
}

.dropdown summary:focus {
  outline: none;
}

.dropdown ul {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  margin: 20px 0 0 0;
  padding: 20px 0;
  width: 160px;
  left: 50%;
  margin-left: calc((var(--dropdown-width) / 2) * -1);
  box-sizing: border-box;
  z-index: 2;

  background: var(--dropdown-background);
  border-radius: 6px;
  list-style: none;
}

.dropdown ul li {
  padding: 0;
  margin: 0;
}

.dropdown ul li a:link,
.dropdown ul li a:visited {
  display: inline-block;
  padding: 10px 0.8rem;
  width: 100%;
  box-sizing: border-box;
  text-transform: capitalize;
  color: #444444;
  text-decoration: none;
}

.dropdown ul li a:hover {
  background-color: #bc0000;
  color: var(--dropdown-background);
}

/* Dropdown triangle */
.dropdown ul::before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  top: -10px;
  left: 50%;
  margin-left: -10px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent var(--dropdown-background) transparent;
}

/* Close the dropdown with outside clicks */
.dropdown > summary::before {
  display: none;
}

.dropdown[open] > summary::before {
  content: " ";
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

@media only screen and (max-width: 945px) {
  .fix-pro-nav {
    /* position:unset !important; */
    width: 100%;
    background-color: var(--body_background);
  }
}

@media only screen and (max-width: 600px) {
  .sc-bcXHqe {
    display: none;
  }
  .mobile-menu-nav {
    display: flex !important;
  }

  a.button {
    font-size: 15px;
  }
  .ul-n {
    display: none;
  }
  .fle1-nav {
    padding: 10px 20px;
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .nav-logo {
    cursor: pointer;
    width: 100px;
  }
}
.sidebar-navbar-mobile {
  transition: 0.5s;
}

.user-m {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.nav-h-1 {
  font-size: 17px;
  font-weight: 100;
}
@media only screen and (max-width: 500px) {
  .user-n1 {
    display: none !important;
  }
}

.user-n1 {
  display: flex;
  justify-content: center;
  text-transform: capitalize;
  align-items: center;
}


.gap-sv{
  padding: 10px;
  background-color: #dbdbdb;
  margin: 3px;
  display: flex;
  justify-content: space-evenly;
}

.gap-svg-h1{
font-size: 12.5px;

}

.gap-svg-hLogo:hover {
  color: black;
}

.markasrednotifi{
  margin-left: 10px;
  cursor: pointer;
}

.ho-ull > ul {
  height: 200px;
  overflow-y: scroll;
}

.dropdown1 ul {
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  position: absolute;
  margin: 20px 0 0 0;
  padding: 20px 10px;
  width: 300px;
  margin-right: 10px;
  right: 0%;
  /* left: 50%; */
  margin-left: calc((var(--dropdown-width) / 1) * -1.5);
  box-sizing: border-box;
  z-index: 2;
  background: white;
  border-radius: 6px;
  list-style: none;
}

.dropdown1 ul::-webkit-scrollbar {
  display: none;
}

.notifcationbadge{
  font-size: 12px;
  top: -15%;
  right: -5%;
  border: #bc0000 solid 1px;
  position: absolute;
padding: 0px 5px;
font-weight: bold;
background-color: white;
border-radius: 50px;
  color: red;
}

/* .dropdown1 ul::before {
  content: " ";
  overflow: unset !important;
  position: absolute;
  width: 0;
  height: 0;
  top: -10px;
  right: 11%;
  margin-left: -13px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent var(--dropdown-background) transparent;
} */

.dropdown1 summary {
  list-style: none;
  list-style-type: none;
}

.dropdown1 > summary::-webkit-details-marker {
  display: none;
}

.dropdown1 summary:focus {
  outline: none;
}

.dropdown1 summary:focus {
  outline: none;
}

.dropdown ul li {
  padding: 0;
  margin: 0;
}

.dropdown1 ul li div:link,
.dropdown1 ul li div:visited {
  display: inline-block;
  padding: 10px 0.8rem;
  width: 100%;
  box-sizing: border-box;

  color: #444444;
  text-decoration: none;
}

/* .dropdown1 ul li div:hover {
  background-color: #bc0000;
  color: var(--dropdown-background);
} */

/* Dropdown triangle */

/* Close the dropdown with outside clicks */
.dropdown1 > summary::before {
  display: none;
}

.dropdown1[open] > summary::before {
  content: " ";
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.batchnumbernotification {
  position: absolute;
  background-color: #bc0000;
  color: white;

  border-radius: 15px;
  padding: 5px 10px;
  transform: scale(0.6);
  margin-top: -13px;
  margin-left: 9px;
}
