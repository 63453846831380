.ForumBody{
    width: 100%;
    height: calc(98vh - 4.0rem);
    flex: 1;
  
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    display: flex;
   
}

.ForumBodySearch{
    width: 100%;
   
}

.ForumBodyMain{
    width: 100%;
}

#search-fs {
    position: relative;
    overflow: hidden;
    margin: 2px auto;
    width: 600px;
    max-width: 100%;
}
#search-fs .fa {
    position: absolute;
    left: 6%;
    top: 40%;
    color: #555;
}
#search-fs input#search-text {
    border: 0;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.30), 0 1px 3px 1px rgba(60,64,67,.15);
    padding: 20px 15px 20px 60px;
    margin: 12px;
    border-radius: 70px;
    width: 580px;
  /* font-family: &#39;Google Sans&#39;; */
    font-size: 16px;
    max-width: 100%;
    outline: none;
}
#search-fs button {
    background: #bc0000;
    border: none;
    color: #ffffff;
  /* font-family: &#39;Google Sans&#39;; */
    text-transform: uppercase;
    font-weight: 700;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px 30px;
    border-radius: 30px;
    margin: 17px;
    cursor: pointer;
    outline: none;
  
}
  #search-fs button:hover{background:#0774ff;color:#fff;transition: all .4s}



  .float-container {
    cursor: pointer;
    position: fixed;
    bottom: 5%;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    width: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.float-container a {
    z-index: 99;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 300px;
    height: 60px;
    margin-right: -230px;
    /* margin-bottom: 10px; */
    padding: 10px 20px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    color: white;
    border-color: #46b8da;
    border-radius: 30px 0 0 30px;
    background-color: #bc0000;
    -webkit-box-shadow: 0 2px 4px #7d7d7d;
    box-shadow: 0 2px 4px #7d7d7d;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    gap: 20px;
    -ms-flex-pack: start;
    justify-content: flex-start;
    font-family: sans-serif;
}

.float-container p{
    font-size: 18px;
    color: white;
font-family: 'Montserrat', sans-serif;
font-weight: 600;
margin-bottom: 0px !important;

}

.float-container a:hover {
    margin-right: 0;
    background-color: #bc0000;
    color: white;
    -webkit-box-shadow: 0 2px 4px #7d7d7d;
    box-shadow: 0 2px 4px #7d7d7d;
}

/* Icon settings - remove if not needed*/
.float-container .icon:before {
    font-family: "Font Awesome 5 Free";
    margin-right: 25px;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}

.icon.one:before {
    content: "\f073";
}

.icon.two:before {
    content: "\f086";
}

.icon.three:before {
    content: "\f1d8";
}

/* Media queries */
@media screen and (max-width:440px)
{
	.float-container .icon:last-child {
		display: none;
	}

		.float-container a.icon
    {
        right: 0;
        bottom: 0;

        width: 100%;
        margin-right: 0;
        margin-bottom: 0;
        padding: 5px;

        border-radius: 0;
        -webkit-box-shadow: 0 0 0 #7d7d7d;
                box-shadow: 0 0 0 #7d7d7d;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
		border-left: 1px solid darkorange;
		border-right: 1px solid darkorange;
    }
}

