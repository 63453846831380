.pricing-head{
    text-align: center;
}
.pricing-head>h1{
  color: #bc0000;
  font-size: 3rem;
}
.pricing-head>p{
  font-size: 1.2rem;
  text-transform: uppercase;

  font-weight: bold;
    
}