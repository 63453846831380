.ReplyForumBody{
 
        width: 100%;
        height: calc(98vh - 4rem);
      
     
}

.user-reply-to1{

        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .user-data-t1>h1 {
        font-size: 13px;
        text-transform: capitalize;
        margin: 0;

    }

    .user-data-t1>p {
        font-size: 12px;
        text-transform: capitalize;
        margin: 0;

    }

    .reply-to-reply-p {
        margin: 0;
        font-size: 14px;
    }

    .d-f-reply {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .forum-to-reply{
        display: flex;
        flex-direction: column;
        gap: 10px; 
    }
    .btn-reply-s11{
        padding: 5px 20px;
        background: #bc0000;
        border-radius: 30px;
        border: none !important;
        font-size: 16px;
        color: white !important;

    }

    .aud-rr-1{
        margin: 0;
    }

    .btn-rec-fun{
        background-color: white;
    }