.ProfileBody{
    width: 100%;
    height: calc(98vh - 4.0rem);
}



@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css?family=Bree+Serif&display=swap");



.profile-header {
  background: #fff;
  width: 100%;
  display: flex;
  height: 190px;
  position: relative;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
}

.profile-img {
  float: left;
  width: 340px;
  height: 200px;
}

.profile-img p {
  border-radius: 50%;
  height: 230px;
  width: 230px;
  border: 5px solid #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 70px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  left: 50px;
  top: 20px;
  z-index: 5;
  background: #fff;
}

.profile-nav-info {
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 60px;
}

.right-side{
  width: 100%;
  height: 450px;
  /* background-color: red; */
}

.profilereacttabmain{
  list-style: none ;
}

.profilereactabs{
  list-style: none !important;
  display: flex;
  margin-top: 15px;
  gap: 10px;

}

@media (max-width: 900px) {
  .profilereactabs{
    list-style: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    gap: 10px;
    padding-left: 0 !important;
  
  }
}

.profilereactabsbutton{
width: 120px;
height: 50px;
background-color: rgb(49, 49, 49);
cursor: pointer;
color: white;
display: flex;
justify-content: center;
align-items: center;
border: 1px solid black;
border-radius: 5px !important;
}

.TabPanelReactTabs {
  display: flex;
  justify-content: center;
  height: 290px;
  align-items: center;
  justify-content: center;
}


.react-tabs__tab--selected{
 border: 1px solid black;
 border-radius: 5px !important;
 cursor: pointer;
}

.profile-nav-info h3 {
  font-variant: small-caps;
  font-size: 2rem;
  font-family: sans-serif;
  font-weight: bold;
}

.profile-nav-info .address {
  display: flex;
  font-weight: bold;
  color: #777;
}

.profile-nav-info .address p {
  margin-right: 5px;
}

.profile-option {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  outline: none;
  background: #bc0000;
}

.profile-option:hover {
  background: #fff;
  border: 1px solid #bc0000;
}
.profile-option:hover .notification i {
  color: #bc0000;
}

.profile-option:hover span {
  background: #bc0000;
}

.profile-option .notification i {
  color: #fff;
  font-size: 1.2rem;
  transition: all 0.5s ease-in-out;
}

.profile-option .notification .alert-message {
  position: absolute;
  top: -5px;
  right: -5px;
  background: #fff;
  color: #bc0000;
  border: 1px solid #bc0000;
  padding: 5px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  font-weight: bold;
}

.main-bd {
  width: 100%;
  display: flex;
  /* padding-right: 15px; */
}

.profile-side {
  width: 300px;
  background: #fff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  padding: 90px 30px 20px;
  font-family: "Bree Serif", serif;
  /* margin-left: 10px; */
  z-index: 99;
}

.profile-side p {
  margin-bottom: 7px;
  color: #333;
  font-size: 14px;
}

.profile-side p i {
  color: #bc0000;
  margin-right: 10px;
}

.mobile-no i {
  transform: rotateY(180deg);
  color: #bc0000;
}

.profile-btn {
  display: flex;
}

.profile-btn a{
  background-color: #bc0000;
  color: white !important;
  padding: 10px 15px;
  border-radius: 5px;
}

button.chatbtn,
button.createbtn {
  border: 0;
  padding: 10px;
  width: 100%;
  border-radius: 3px;
  background: #bc0000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #fff;
  font-family: "Bree Serif";
  font-size: 1rem;
  margin: 5px 2px;
  cursor: pointer;
  outline: none;
  margin-bottom: 10px;
  transition: background 0.3s ease-in-out;
  box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.3);
}

button.chatbtn:hover,
button.createbtn:hover {
  background: green;
}

button.chatbtn i,
button.createbtn i {
  margin-right: 5px;
}

.user-rating {
  display: flex;
}

.user-rating h3 {
  font-size: 2.5rem;
  font-weight: 200;
  margin-right: 5px;
  letter-spacing: 1px;
  color: #666;
}

.user-rating .no-of-user-rate {
  font-size: 0.9rem;
}

.rate {
  padding-top: 6px;
}

.rate i {
  font-size: 0.9rem;
  color: rgba(228, 0, 70, 1);
}





.profile-body {
  width: 100%;
  z-index: -1;
}

.tab {
  display: none;
}

.tab {
  padding: 20px;
  width: 100%;
  text-align: center;
}

@media (max-width: 1100px) {
  .profile-side {
    width: 250px;
    padding: 90px 15px 20px;
  }

  .profile-img p {
    height: 200px;
    width: 200px;
    left: 50px;
    top: 50px;
  }
}

@media (max-width: 900px) {
  body {
    margin: 0 20px;
  }

  .profile-header {
    display: flex;
    height: 100%;
    flex-direction: column;
    text-align: center;
    padding-bottom: 20px;
  }

  .profile-img {
    float: left;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
  }

  .profile-img p {
    position: relative;
    height: 200px;
    width: 200px;
    left: 0px;
  }

  .profile-nav-info {
    text-align: center;
  }

  .profile-option {
    right: 20px;
    top: 75%;
    transform: translateY(50%);
  }

  .main-bd {
    flex-direction: column;
    padding-right: 0;
  }

  .profile-side {
    width: 100%;
    text-align: center;
    padding: 20px;
    margin: 5px 0;
  }

  .profile-nav-info .address {
    justify-content: center;
  }

  .user-rating {
    justify-content: center;
  }
}

@media (max-width: 400px) {



  .profile-option {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 15px;
    top: 83%;
  }

  .profile-option .notification .alert-message {
    top: -3px;
    right: -4px;
    padding: 4px;
    height: 15px;
    width: 15px;
    font-size: 0.7rem;
  }

  .profile-nav-info h3 {
    font-size: 1.9rem;
  }

  .profile-nav-info .address p,
  .profile-nav-info .address span {
    font-size: 0.7rem;
  }
}
#see-more-bio,
#see-less-bio {
  color: blue;
  cursor: pointer;
  text-transform: lowercase;
}
.tab h1 {
  font-family: "Bree Serif", sans-serif;
  display: flex;
  justify-content: center;
  margin: 20px auto;
}


.plan-bg{

  background-position: center;
  background-size: cover;
  /* min-height: 618px; */
  height: 100vh;
}

.tb-plan{
width: 90%;
}
.tb-plan1{
  width: 100%;
  display: flex;
  justify-content: center;
  
  padding: 100px 0px 0px 0px;
}

.plan-h2{

    font-weight: 500;
    font-size: 20px;
  }

    
    
    
    
    /*  */
    .tb-1{
      border: 1px solid #ccc;
      border-collapse: collapse;
      margin: 0;
      padding: 0;
      width: 100%;
      table-layout: fixed;
    }
    
    .tb-cap{
      font-size: 1.5em;
      margin: .5em 0 .75em;
    }
    
   .tr-plan{
      background-color: #f8f8f8;
      border: 1px solid #ddd;
      padding: .35em;
    }
    
    .tb-cap>th,
      td{
          padding: .625em;
          text-align: center;
      }
      .th-plan{

          padding: .625em;
          text-align: center;
      }
      
      .th-plan{
      font-size: .85em;
      letter-spacing: .1em;
      text-transform: uppercase;
    }
    
    @media screen and (max-width: 900px) {
      .tb-1{
        border: 0;
      }
    
      .tb-cap{
        font-size: 1.3em;
      }
      
      .tb-1>thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
      
    .tr-plan{
        border-bottom: 1px solid #ddd;
        display: block;
        margin-bottom: .625em;
      }
      
      .td-plan{
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: .8em;
        text-align: right;
      }
      
      .td-plan::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
      }
      
      .td-plan:last-child {
        border-bottom: 0;
      }
    }
    .yes-1{
color:#32cd32;
        
      }
    
    



/* CSS */
.button-43 {
  background-image: linear-gradient(-180deg, #e23737 0%, #c81e1e 100%);
  /* border-radius: .5rem; */
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-size: 16px;
  justify-content: center;
  padding: 1rem 1.75rem;
  text-decoration: none;
  width: 100%;
  border: 0;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-43:hover {
  background-image: linear-gradient(-180deg, #c91d1d 0%, #9c1717 100%);
}

@media (min-width: 768px) {
  .button-43 {
    padding: 1rem 2rem;
  }
}