.App {
  text-align: center;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@900&display=swap');


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.div-btn-sb-1 {
  display: flex;
  justify-content: center;
  padding: 30px 0px;
}

.div-btn-sb2{
  width: fit-content;
  display: flex;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 50px;
background-color:  lightgrey;

}

.sub-b2{
  border-radius: 50px;

  padding: 5px 20px;
background-color:  lightgrey;
}
.sub-b2{
  border-radius: 50px;

  padding: 5px 20px;
background-color:  lightgrey;
}

.sub-b1{
  border-radius: 50px;

  padding: 5px 20px;
  color: white;
background-color:  #bc0000;
}


.home-post-1{
  padding: 90px 0px 0px 0px;
}

.logo-auth-h{
  width: 300px;
}

.loader-sp {
    align-items: center;
    background: #ffffff54;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 500;
}

svg{
  cursor: pointer;
}