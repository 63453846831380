.Modalllling{
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.Modallllingh1{
    font-size: 20px;
    text-align: center;
}


.Booxxxxxx1{
    width: 150px;
    height: 150px;
    /* background-color: red; */
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
}

.Booxxxxxx2{
    width: 150px;
    height: 150px;
    /* background-color: red; */
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
}

.Boxxxxx{
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
   
    cursor: pointer;
}

.Booxxxxxx2:hover{
    opacity: 1;
}

.Booxxxxxx1:hover{
    opacity: 1;
}


.boxxxxxxxxx1p{
text-align: center;
font-size: 17px;
font-weight: bold;
line-height:normal;
margin-bottom: 0px;
}


.boxxxxxxxxx1p1{
    text-align: center;
font-size: 14px;
/* font-weight: bold; */
line-height:normal;

}

.cpa-v-section{
    
}