.heading-case{
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);

padding: 20px 0px;
  font-size: 20px;
  text-align: center;
}
.heading-case2{
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);

padding: 0px 0px 20px 0px;
  font-size: 20px;
  text-align: center;
}
.heading-case3{
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
  padding: 20px 0px 0px 0px;

  font-size: 20px;
  text-align: center;
}


.card-case-img{

    width: 170px;

    max-width: unset !important;
   height: 100%;
}
.case-study-section{

}
.case-card-d2{
display: flex;
    border: 1px solid lightgray;
    margin-top: 20px;
cursor: pointer;
}
.content-case-study {
 
    padding: 10px;
}
.athlete-name-case{
    font-size: 20px;
    font-weight: 100;
}

.athlete-p-tab{
    font-size: 14px;
}
.image-case-section{
    width: 170px;

}
@media (max-width: 550px) { 

  .course-view{
    margin-bottom: 50px;
  }
    .case-card-d2 {

        flex-direction: column ;
    }
    .image-case-section {
        width: unset;
    }
    .card-case-img {
        width: 100%;
     
        height: 100%;
    }
    .play-case{
        width: 100% !important;
        height: 100% !important;
      }
  }

  .head-date3>h1{
    font-size: 15px;
    font-weight: 100;
margin: 0;  }
  .dates-Athlete {
    display: flex;
    gap: 10px;

  }

  .head-date1{
    border: 1px solid lightgrey;
    padding: 15px 20px;
    cursor: pointer;
  }

  .swiper-ddd{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .case-img-s{
    width: 170px;
    border: 1px solid lightgray;
    height: 170px;
  }


  .scroll-ss-d1{
    display: flex;
    gap: 20px;
    padding: 20px 0px 50px 0px;
  }

  .scroll-ss{
    
  }

  .case-vv-1{

  }


.case-btn-choose{


  display: flex;

}
.case-btn-choose>button{
color: gray;

    font-size: 14px;
    padding: 10px 25px;
    width: 100%;
    background-color: white;
    border-radius: 5px;

}
.bold-btn-text{
  color: black !important;
 border: 1px solid ;

}

.side-ss-case{

}

.slide-box-section{
  grid-gap: 10px;
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill,minmax(6rem,1fr));
  padding: 5px 0 !important;
  gap: 5px;
}

.section-case-d1{
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}



.case-side-rh{
  border: 1px solid lightgray;
  border-radius: 5px;
  overflow: hidden;
  padding: 10px;
}


.lh-array-1img>img{
  width: 100%;
  border-radius: 5px;
  border: 1px solid lightgray;
}

.section-case-d2{
  width:50%;

}

.video-av-not{
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
  padding: 20px 0px 20px 0px;
  font-size: 16px;
  text-align: center;
}